exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-action-js": () => import("./../../../src/pages/authAction.js" /* webpackChunkName: "component---src-pages-auth-action-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-uniux-docs-columned-apps-intro-js": () => import("./../../../src/pages/uniux/docs/columned-apps-intro.js" /* webpackChunkName: "component---src-pages-uniux-docs-columned-apps-intro-js" */),
  "component---src-pages-uniux-docs-index-js": () => import("./../../../src/pages/uniux/docs/index.js" /* webpackChunkName: "component---src-pages-uniux-docs-index-js" */),
  "component---src-pages-uniux-docs-install-js": () => import("./../../../src/pages/uniux/docs/install.js" /* webpackChunkName: "component---src-pages-uniux-docs-install-js" */)
}

